<template>
  <div id="action-sesi">
    <!-- Rascunho -->
    <div v-if="ehRascunho">
      <button-icon
        color="#2772C0"
        size="18"
        :feather-icon="ehRascunho ? 'EditIcon' : 'EyeIcon'"
        @action="editarPedido"
      />

      <button-icon
        color="#2772C0"
        size="18"
        feather-icon="Trash2Icon"
        @action="deletarPedido"
      />
    </div>
    <!-- Efetivada -->
    <div v-else>
      <button-icon
        color="#2772C0"
        size="18"
        feather-icon="EyeIcon"
        @action="visualizarPedido"
      />
    </div>

    <ExcluirPedidoModal
      :Pedido="pedidoAcao"
      v-if="pedidoAcao != null"
      @fecharModalExclucaoPedido="fecharModalExclucaoPedido"
      @fecharModalRetorno="fecharModalRetorno"
    />
  </div>
</template>

<script>
import {
  AdesaoSetChooseOrder,
  AdesaoSetChooseOrderAdhesion,
} from "@/views/custom-pages/adesao/Purchase/adesaoHelpers.js";
import ExcluirPedidoModal from "@/views/custom-pages/adesao/components/ExcluirPedidoModal/ExcluirPedidoModal";
import ButtonIcon from "@/views/components/custom/Buttons/ButtonIcon/ButtonIcon";
export default {
  props: {
    Order: {
      required: true,
    },
  },
  data() {
    return {
      pedidoSelecionado: null,
    };
  },
  components: {
    ButtonIcon,
    ExcluirPedidoModal,
  },
  methods: {
    editarPedido() {
      AdesaoSetChooseOrder(this.Order, false);
    },
    visualizarPedido() {
      AdesaoSetChooseOrderAdhesion(this.Order);
    },
    deletarPedido() {
      this.pedidoSelecionado = this.Order;
    },
    fecharModalExclucaoPedido() {
      this.pedidoSelecionado = null;
    },
    fecharModalRetorno() {
      this.$emit("deleted");
    },
  },
  computed: {
    ehRascunho() {
      return this.Order.pedido_situacao == "Rascunho";
    },
    pedidoAcao() {
      return this.pedidoSelecionado;
    },
  },
};
</script>
