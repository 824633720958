<template>
	<div>
		<section v-if="loadingTable">
			<div class="spinner-area">
				<b-spinner variant="custom" label="Loading..." />
			</div>
		</section>

		<section v-if="!loadingTable">
		<b-row>
			<b-col md="12" lg="6">
				<h1 class="px-2">{{ titlePage }}</h1>
			</b-col>
		</b-row>

		<b-row class="my-2 w-100">
			<section class="filters pl-1 w-100">
				<b-row class="m-0 pl-1">
					<b-col lg="4" md="6" sm="7">
						<b-form-group 
							label="Número do pedido"
							label-for="numero-pedido"
							label-class="font_size_label_filter"
						>
							<b-form-input
								id="numero-pedido"
								v-mask="'#'.repeat(30)"
								placeholder="Número do pedido"
								v-model="search.numeroPedido"
							/>
						</b-form-group>
					</b-col>
					<b-col lg="4" md="6" sm="7">
						<b-form-group 
							label="Empresa"
							label-for="empresa"
							label-class="font_size_label_filter"
						>
							<b-form-input
								id="empresa"
								placeholder="Empresa realizadora"
								v-model="search.empresa"
							/>
						</b-form-group>
					</b-col>
					<b-col lg="4" md="6" sm="7">
						<b-form-group 
							label="CNPJ"
							label-for="cnpj"
							label-class="font_size_label_filter"
						>
							<b-form-input
								id="cnpj"
								v-mask="'##.###.###/####-##'"
								placeholder="00.000.000/0000-0"
								v-model="search.cnpj"
							/>
						</b-form-group>
					</b-col>
					<b-col lg="4" md="6" sm="7">
						<b-form-group 
							label="Campanha"
							label-for="campanha"
							label-class="font_size_label_filter"
						>
							<v-select
								id="campanha"
								placeholder="Selecione uma campanha"
								v-model="search.idCampanha"
								:options="campanhas"
								:reduce="(option => option.id_campanha)"
								label="campanha_descricao"
								style="width: 100%;"
							/>
						</b-form-group>
					</b-col>
					<b-col lg="4" md="6" sm="7">
						<b-form-group 
							label="Data"
							label-for="data"
							label-class="font_size_label_filter"
						>
							<b-input-group>
								<b-form-input
									id="data"
									v-model="searchData"
									v-mask="'##/##/####'"
									placeholder="00/00/0000"
									autocomplete="off"
									@input="setInput"
								/>
								<b-input-group-append>
									<b-form-datepicker
										class="form-control d-flex align-items-center"
										locale="pt-BR"
										v-model="search.data"
										button-only
										right
										:hide-header="true"
										label-help="Selecione a data de validade"
										button-variant="custom-blue"
										clearable
										@input="setPicker"
									/>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col lg="5" md="6" sm="7" class="mt-2">
						<b-button @click="filtrarAdesoes()" variant="primary-button">
							<feather-icon icon="SearchIcon" class="mr-50" />
							<span class="align-middle">Pesquisar</span>
						</b-button>
		
						<b-button type="reset" class="ml-2 cor_botao" @click="limparPesquisa()" variant="outline-primary">
							<feather-icon icon="XIcon" class="mr-50"/>
							<span class="align-middle">Limpar</span>
						</b-button>
					</b-col>
			   </b-row>
				<hr>
				<b-row class="d-flex align-items-center pb-1" v-if="!tabela.semDados">
					<span class="mr-1 pl-3" for="tableSize">Mostrar</span>
					<v-select
						id="tableSize"
						v-model="paginationData.defaultSize"
						@input="updateTableSize($event)"
						:options="tabela.quantidadeLinhasVisualizar"
						:clearable="false"
						style="width: 100px;"
					/>
				</b-row>
			</section>

			<b-col cols="12">
				<b-alert variant="primary" :show="tabela.semDados" class="mx-2 mt-5">
					<div class="alert-body d-flex justify-content-center">
						<span class="text-primary">
							<strong class="text-primary">Nenhum registro encontrado.</strong>
						</span>
					</div>
				</b-alert>

				<b-alert variant="primary" :show="tabela.erroTabela" class="mx-2 mt-5">
					<div class="alert-body d-flex justify-content-center">
						<span class="text-primary">
							<strong class="text-primary">Sistema de busca indisponível no momento.</strong>
						</span>
					</div>
				</b-alert>
			</b-col>

			<b-col v-if="showTable" cols="12" class="mb-2">
				<b-table
					id="listOrderTable"
					responsive
					:busy.sync="tabela.tabelaOcupada"
					:no-local-sorting="true"
					:fields="getFieldsTable"
					:items="tabela.items"
					@context-changed="handleOrderTable"
				>
					<template #cell(numero_pedido)="row">
						<span>
							<strong>#{{ row.value }}</strong>
						</span>
					</template>
					<template #cell(nome_campanha)="row">
						<span>{{ row.value }}</span>
					</template>
					<template #cell(realizado_por)="row">
						<span>{{ posted ? row.value : "SESI" }}</span>
					</template>
					<template #cell(data_processamento)="row">
						<span>{{ moment(row.value).format("DD/MM/YYYY HH:mm") }}</span>
					</template>
					<template #cell(actions)="row">
						<ActionButtonCompany v-if="ehAdesaoEmpresa" :Order="row.item" />

						<ActionButtonSesi
							v-else
							:Order="row.item"
							@deleted="filterPurchases"
						/>
					</template>
				</b-table>
			</b-col>

			<b-col class="px-3" sm="12" v-if="showTable">
				<CustomPagination
					:paginacao="paginationData"
					@page-cliked="updateCurrentPage"
				/>
			</b-col>
		</b-row>
		</section>
	</div>
</template>

<script>
import {
	BForm,
	BRow,
	BCol,
	BBadge,
	BFormGroup,
	BInputGroup,
	BFormInput,
	BInputGroupAppend,
	BFormDatepicker,
	BFormSelect,
	BButton,
	BLink,
	BTable,
	BTableSimple,
	BTbody,
	BTr,
	BTd,
	BSpinner,
	BAlert,
	BIconPlusCircle,
} from "bootstrap-vue";
import CustomPagination from "@/views/components/custom/pagination/CustomPagination";
import moment from "moment";
import vSelect from "vue-select";
import ButtonIcon from "@/views/components/custom/Buttons/ButtonIcon/ButtonIcon";
import ActionButtonSesi from "./Parts/ActionButtonSesi.vue";
import ActionButtonCompany from "./Parts/ActionButtonCompany.vue";
import { actions, subjects } from "@/libs/acl/rules";

export default {
	components: {
		vSelect,
		BForm,
		BRow,
		BCol,
		BBadge,
		BFormGroup,
		BInputGroup,
		BFormInput,
		BInputGroupAppend,
		BFormDatepicker,
		BFormSelect,
		BButton,
		BLink,
		BTable,
		BTableSimple,
		BTbody,
		BTr,
		BTd,
		BSpinner,
		BAlert,
		BIconPlusCircle,
		CustomPagination,
		ButtonIcon,

		ActionButtonSesi,
		ActionButtonCompany,
	},

	data() {
		return {
			moment,
			titlePage: "",

			search: {
				numeroPedido: null,
				empresa: null,
				idCampanha: null,
				data: null,
				cnpj: null,
			},
			searchData: null,

			campanhas: [],

			route: "",
			posted: false,
			canceledParam: false, 

			loadingTable: true,
			showTable: false,

			ehAdesaoEmpresa: this.$can(actions.VISUALIZAR, subjects.ADESAO_EMPRESA),

			paginationData: {
				currentPage: 0,
				totalLines: 0,
				fromLine: 0,
				toLine: 0,
				defaultSize: 10,
			},

			tabela: {
				semDados: false,
				erroTabela: false,
				tabelaOcupada: false,
				quantidadeLinhasVisualizar: [10, 25, 50, 100],
				ordem: "",
				campoOrdenado: "",
				fields: [
					{
						key: "numero_pedido",
						label: this.posted ? "ADESÃO" : "PEDIDO",
						sortable: true,
						class: "text-center numero_pedido",
					},
					{
						key: "nome_campanha",
						label: "CAMPANHA",
						sortable: true,
						class: "text-left nome_campanha",
					},
					{
						key: "realizado_por",
						label: "REALIZADO POR",
						sortable: false,
						class: "text-left realizado_por",
					},
					{
						key: "data_processamento",
						label: "INICIADO",
						sortable: true,
						class: "text-center data_processamento",
					},
					{
						key: "actions",
						label: "AÇÕES",
						class: "width-custom-column text-center actions",
					},  
				],
				items: [],
			},
		};
	},

	mounted() {
		this.$http.get(this.$api.campanha()).then(({ data }) => {
			this.campanhas = data;	
		});
	},

	methods: {
		listOrdersPosted() {
			this.posted = true;

			this.titlePage = "Lista de Adesões Lançadas";
			this.route = this.$api.adesao();

			this.findAll();
		},

		listOrdersPending() {
			this.posted = false;

			this.titlePage = "Lista de Pedidos em Rascunho";
			this.route = this.$api.pedido();

			this.findAll();
		},

		listOrdersCanceled() {
			this.canceledParam = true;
			let field = this.tabela.fields.find(item => item.key == "data_processamento")
			field.label = "CANCELADO EM"  
			this.titlePage = "Lista de Pedidos Cancelados";
			this.route = this.$api.adesao();

			this.findAll();
		},

		findAll() {
			this.tabela.erroTabela = false;
			this.tabela.semDados = false;
			this.loadingTable = true;

			this.$http.get(this.route, {params: this.setParams()}).then((response) => {
				if (response.status === 200) {
					if (response.data.data.length > 0) {
						this.showTable = true;
						this.tabela.items = response.data.data;
						this.tabela.tabelaOcupada = false;
						this.handlePagination(response.data);
						return;
					}

					this.tabela.semDados = true;
					this.showTable = false;
				}
			}).catch(() => {
				this.tabela.erroTabela = true;
				this.showTable = false;
			});

			this.loadingTable = false;
		},

		filterPurchases() {
			if (this.getPosted) {
				this.listOrdersPosted();
			} else {
				this.canceledParam ? this.listOrdersCanceled() : this.listOrdersPending();
			}
		},

		handleOrderTable(context) {
			this.tabela.campoOrdenado = context.sortBy;
			this.tabela.ordem = context.sortDesc ? "desc" : "asc";

			this.findAll();
		},

		formatCnpj(cnpj){
			return cnpj.replace(/\D/g, "")
		},

		setParams() {
			return {
				colunaNome: this.tabela.campoOrdenado,
				colunaOrdem: this.tabela.ordem,
				porPagina: this.paginationData.defaultSize,
				perPage: this.paginationData.defaultSize,
				page: this.paginationData.currentPage,
				canceled: this.canceledParam,
				numeroPedido: this.search.numeroPedido,
				empresa: this.search.empresa,
				idCampanha: this.search.idCampanha,
				data: this.search.data,
				cnpjEmpresa: this.search.cnpj ? this.formatCnpj(this.search.cnpj) : null
			};
		},

		handlePagination(data) {
			this.paginationData.fromLine = data.from;
			this.paginationData.toLine = data.to;
			this.paginationData.totalLines = data.total;
			this.paginationData.currentPage = data.current_page;
		},

		updateTableSize(event) {
			if(!event) {
				this.paginationData.defaultSize = 10;
			}

			this.paginationData.currentPage = 1;
			this.findAll();
		},

		updateCurrentPage(page) {
			this.paginationData.currentPage = page;
			this.findAll();
		},

		setPicker() {
            this.searchData = moment(this.search.data).format('DD/MM/YYYY');
        },

        setInput() {
            this.search.data = 
				this.searchData.length > 0 
				? moment(this.searchData, 'DD/MM/YYYY').format('YYYY-MM-DD')
				: null;
        },

		filtrarAdesoes() {
			this.paginationData.currentPage = 1
			this.filterPurchases();
        },

		limparPesquisa() {
			this.search.numeroPedido = null;
			this.search.empresa = null;
			this.search.idCampanha = null;
			this.search.data = null;
			this.search.cnpj = null;
			this.searchData = null;
        },
	},

	computed: {
		getPosted() {
			return this.posted;
		},

		getFieldsTable() {
			return this.tabela.fields;
		},
	},
};
</script>

<style lang="scss">
	@import "./style.scss";

	.font_size_label_filter{
        font-size: 15px;
        margin-top: -5px;
    }

	::v-deep table#listOrderTable > tbody > tr > td.numero_pedido {
		width: 5%;
	}
	::v-deep table#listOrderTable > tbody > tr > td.nome_campanha {
		width: 30%;
	}
	::v-deep table#listOrderTable > tbody > tr > td.realizado_por {
		width: 40%;
	}
	::v-deep table#listOrderTable > tbody > tr > td.data_processamento {
		width: 15%;
	}
	::v-deep table#listOrderTable > tbody > tr > td.actions {
		width: 5%;
	}
</style>