<template>
  <div id="action-company">
    <button-icon
      color="#2772C0"
      size="18"
      feather-icon="EyeIcon"
      @action="verAdesao"
    />
  </div>
</template>
  
<script>
import ButtonIcon from "@/views/components/custom/Buttons/ButtonIcon/ButtonIcon";
import { AdesaoSetChooseOrderAdhesion } from "@/views/custom-pages/adesao/Purchase/adesaoHelpers.js";
export default {
  props: {
    Order: {
      required: true,
    },
  },
  components: {
    ButtonIcon,
  },
  methods: {
    verAdesao() {
      AdesaoSetChooseOrderAdhesion(this.Order);
    },
  },
};
</script>
