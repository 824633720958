<template>
  <div id="modal-exclusao-pedido">
    <!-- modal nao-eh-rascunho -->
    <b-modal
      id="abrir-modal-nao-eh-rascunho"
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      centered
    >
      <div :class="classesModal">
        <feather-icon icon="XCircleIcon" class="text-danger mb-2" size="80" />

        <h3 class="text-center">Ação indevida</h3>

        <p class="px-3 text-center">
          Só é possível realizar uma exclusão de um Rascunho.
        </p>

        <div class="d-flex mt-2">
          <button class="btn custom-blue" @click="fecharModal">Ok</button>
        </div>
      </div>
    </b-modal>
    <!-- modal nao-eh-rascunho -->

    <!-- modal eh-rascunho -->
    <b-modal
      id="abrir-modal-eh-rascunho"
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      centered
    >
      <div :class="classesModal">
        <feather-icon icon="XCircleIcon" class="text-danger mb-2" size="80" />

        <h3 class="text-center">Tem certeza que excluir esse pedido?</h3>

        <p class="px-3 text-center">
          Esse pedido será excluído e consequentemente todos os registros
          atrelados a ele.
        </p>

        <div class="d-flex mt-2">
          <button class="btn custom-blue mr-1" @click="fecharModal">
            Cancelar
          </button>
          <button
            class="btn btn-outline-danger"
            @click="deletarPedido"
            :disabled="disabled"
          >
            Sim, excluir
          </button>
        </div>
      </div>
    </b-modal>
    <!-- modal eh-rascunho -->

    <!-- modal retorno exclucao -->
    <b-modal
      id="abrir-modal-retorno-exclusao"
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      centered
    >
      <div :class="classesModal">
        <feather-icon
          :icon="modal.exclusao.icone"
          class="mb-2"
          :class="modal.exclusao.classe"
          size="80"
        />

        <h3 class="text-center">Ação realizada</h3>

        <p class="px-3 text-center">
          <span
            v-for="(msg, index) in modal.exclusao.mensagens"
            :key="index"
            class="d-block"
          >
            {{ msg }}
          </span>
        </p>

        <div class="d-flex mt-2">
          <button class="btn custom-blue mr-1" @click="fecharModalRetorno">
            Ok
          </button>
        </div>
      </div>
    </b-modal>
    <!-- modal retorno exclucao -->
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
export default {
  props: {
    Pedido: {
      type: Object,
      required: true,
    },
  },
  components: {
    BModal,
  },
  data() {
    return {
      classesModal:
        "d-flex flex-column align-items-center justify-center-center my-2",
      modal: {
        naoEhRascunho: null,
        ehRascunho: null,
        exclusao: {
          icone: "XCircleIcon",
          classe: "text-danger",
          mensagens: [],
        },
      },
      disabled: false,
    };
  },
  beforeMount() {
    this.modal = {
      ...this.modal,
      ...{
        naoEhRascunho: !this.Pedido.pedido_situacao == "Rascunho",
        ehRascunho: this.Pedido.pedido_situacao == "Rascunho",
      },
    };
  },
  mounted() {
    if (this.modal.ehRascunho) {
      this.$bvModal.show("abrir-modal-eh-rascunho");
    } else {
      this.$bvModal.show("abrir-modal-nao-eh-rascunho");
    }
  },
  methods: {
    fecharModal() {
      this.modal = {
        ehRascunho: null,
        naoEhRascunho: null,
      };
      this.$bvModal.hide("abrir-modal-eh-rascunho");
      this.$bvModal.hide("abrir-modal-nao-eh-rascunho");

      this.$emit("fecharModalExclucaoPedido");
    },
    fecharModalRetorno() {
      this.disabled = false;
      this.fecharModal();
      this.$bvModal.hide("abrir-modal-retorno-exclusao");
      this.$emit("fecharModalRetorno");
    },
    mostarModalRetorno(success = false, statusValido = false, mensagens = []) {
      if (success) {
        this.modal.exclusao.icone = "CheckIcon";
        this.modal.exclusao.classe = "text-success";
      }

      this.modal.exclusao.mensagens = statusValido
        ? mensagens
        : ["Não foi possível realizar sua solicitação"];

      this.$bvModal.show("abrir-modal-retorno-exclusao");
    },
    async deletarPedido() {
      this.disabled = true;
      await this.$http
        .delete(this.$api.pedidoId(this.Pedido.id_pedido))
        .then(() => {
          this.mostarModalRetorno(true, true, ["Pedido excluído com sucesso."]);
        })
        .catch((errors) => {
          const statusValido =
            errors.response.status == 400 || errors.response.status == 404;
          this.mostarModalRetorno(false, statusValido, [
            errors.response.data.errors,
          ]);
        });
    },
  },
};
</script>

<style>
</style>