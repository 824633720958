<template>
  <div class="content-wrapper">
    <page-header screenName="Ver Pedidos" :linkItems="linkItems" />

    <div class="card acceptances-list">
      <b-tabs active-nav-item-class="text-custom-blue" :align="alignTabs">
        <b-tab title="Lançados" active @click="dispatchOrdersPosted">
          <orders-table ref="ordersPostedTableRef" />
        </b-tab>

        <b-tab v-if="!companyAdmin" title="Rascunhos" @click="dispatchPendingOrders">
          <orders-table ref="ordersPendingTableRef" />
        </b-tab>

        <b-tab v-if="!companyAdmin" title="Cancelados" @click="dispatchCanceledOrders">
          <orders-table ref="ordersCanceledTableRef" />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import { BTabs, BTab, BSpinner } from 'bootstrap-vue'
import OrdersTable from "@/views/custom-pages/adesao/Acceptances/OrdersTable";
import {actions, subjects} from "@/libs/acl/rules";
import { AdesaoResetDependences } from "@/views/custom-pages/adesao/Purchase/adesaoHelpers.js";

export default {
  title: "Ver pedidos",
  
  components: {
    PageHeader,
    BTabs,
    BTab,
    BSpinner,
    OrdersTable,
  },

  mounted() {
    AdesaoResetDependences()
    this.dispatchOrdersPosted()
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Adesão',
          routeName: ''
        },
        {
          name: 'Ver Pedidos',
          active: true
        },
      ],

      alignTabs: 'center',

      ordersPosted: [

      ],
      ordersOutline: [],
    }
  },

  methods: {
    dispatchOrdersPosted() {
      this.$refs.ordersPostedTableRef.listOrdersPosted();
    },

    dispatchPendingOrders() {
      this.$refs.ordersPendingTableRef.listOrdersPending();
    },

    dispatchCanceledOrders() {
      this.$refs.ordersCanceledTableRef.listOrdersCanceled()
    }
  },

  computed: {
    companyAdmin() {
      return this.$can(actions.VISUALIZAR, subjects.ADESAO_EMPRESA);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>

<style>
.nav-tabs .nav-link:after {
    left: 0;
    background: #2771c0b7 !important;
}
</style>